import { getters } from './getters'
import { mutations } from './mutations'
import type { Module } from 'vuex'
import type { IRootState } from '@/store/types'
import type { IUserState } from '@/store/me/types'

export const state: IUserState = {
  name: null,
  email: null,
  role: null,
  companyName: null,
}

const namespaced = false

export const me: Module<IUserState, IRootState> = {
  namespaced,
  state,
  getters,
  mutations,
}
