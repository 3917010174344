




































import Vue from 'vue'
import AppBarLanguageSwitcher from '@/components/AppBarLanguageSwitcher.vue'

export default Vue.extend({
  name: 'AuthLayout',
  components: { AppBarLanguageSwitcher },
  props: {
    backgroundSrc: { type: String, default: require('@/assets/images/loginBackground.png') },
    backgroundPosition: { type: String, default: 'center right' },
    backgroundContain: { type: Boolean, default: false },
    backgroundClass: { type: String, default: '' },
  },
  data: () => ({ innerHeight, backgroundAlt: 'login-background-image' }),
  mounted() {
    addEventListener('resize', () => (this.innerHeight = innerHeight))
  },
})
