








import Vue from 'vue'
import LoggedInLayout from '@/layouts/LoggedInLayout.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import GuestLayout from '@/layouts/GuestLayout.vue'

export default Vue.extend({
  name: 'App',
  components: { GuestLayout, LoggedInLayout, AuthLayout },
  computed: {
    layoutName() {
      const routeMeta = this.$route.meta
      return routeMeta ? routeMeta.layout : undefined
    },
  },
})
