import Vue from 'vue'
import '@/plugins/fragment'
import '@/plugins/composition-api'
import '@/plugins/api'
import '@/plugins/vuelidate'
import App from '@/App.vue'
import { router } from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import '@/plugins/toast'
import '@/plugins/loader'
import '@/plugins/v-tooltip'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
