import axios from 'axios'
import api from '@/plugins/api'
import get from 'lodash/get'
import { router } from '@/router'
import type { ActionTree } from 'vuex'
import type { IAuthState, IAuthenticate2FAResponse, IRefreshTokenResponse } from '@/store/auth/types'
import type { IRootState } from '@/store/types'

export const actions: ActionTree<IAuthState, IRootState> = {
  sendLoginRequest: async ({ commit }, payload): Promise<void> => {
    const axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + payload.token,
      },
    })

    const res = await axiosInstance.post<IAuthenticate2FAResponse>('/2fa/authenticate', { code: payload.code })
    const authData = get(res, 'data.data.authentication', null)
    const userData = get(res, 'data.data.user', null)
    if (authData && userData) {
      await commit('LOGIN', authData)
      await commit('ADD_ME', userData, { root: true })
    }
  },
  sendRefreshTokenRequest: async ({ commit, state }): Promise<void> => {
    const refreshToken = state.refreshToken
    const { data } = await axios.get<IRefreshTokenResponse>('/2fa/refresh', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + refreshToken,
      },
      baseURL: process.env.VUE_APP_BASE_URL,
    })
    if (data.accessToken) {
      const authData = {
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      }
      commit('REFRESH_TOKEN', authData)
    }
  },
  logout: async ({ commit }): Promise<void> => {
    api({ timeout: 3000 })
      .post('/logout')
      .finally(() => {
        commit('LOGOUT')
        commit('REMOVE_ME', null, { root: true })
        return router.push({ name: 'Login' })
      })
  },
  quickLogin: async ({ commit }, res): Promise<void> => {
    const authData = get(res, 'data.data.authentication', null)
    const userData = get(res, 'data.data.user', null)
    await commit('LOGIN', authData)
    await commit('ADD_ME', userData, { root: true })
  },
}
