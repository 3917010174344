import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import store from '@/store'
import { router } from '@/router'
import merge from 'lodash/merge'

export default (axiosOptions: AxiosRequestConfig = {}): AxiosInstance => {
  const defaultOptions = {
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: '',
    },
  }

  const accessToken = store.getters.accessToken
  const options = merge(defaultOptions, axiosOptions)
  if (accessToken) options.headers.Authorization = 'Bearer ' + accessToken

  const axiosIns = axios.create(options)

  axiosIns.interceptors.response.use(
    (response) => response,

    async (error) => {
      if (error.response && error.response.status === 401) {
        return await store
          .dispatch('sendRefreshTokenRequest')
          .then(() => {
            const newRequest = error.config
            newRequest.headers.Authorization = 'Bearer' + store.getters.accessToken

            return axios(newRequest)
          })
          .catch((err) => {
            if (err.response.status === 401 && router.currentRoute.path !== '/login') {
              store.dispatch('logout')
              return router.push({ name: 'Login' })
            }

            return Promise.reject(err)
          })
      }

      return Promise.reject(error)
    },
  )
  return axiosIns
}
