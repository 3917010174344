import Vue from 'vue'
import store from '@/store'
import VueRouter, { RouteConfig } from 'vue-router'
import { UserRoles } from '@/enums/UserRoles'
import { isMobile } from '@/utils/helpers'

Vue.use(VueRouter)

export const routes: Array<RouteConfig> = [
  {
    path: '/no-mobile',
    name: 'LandingForMobileDevices',
    component: () => import(/* webpackChunkName: "no-mobile" */ '@/views/LandingViews/LandingForMobileDevices.vue'),
    meta: {
      layout: 'div',
    },
  },
  {
    path: '/',
    name: 'PublicPublishedSellingTenders',
    component: () =>
      import(
        /* webpackChunkName: "published-selling-tender-list" */ '@/views/PublishedTender/PublishedSellingTendersList.vue'
      ),
    meta: {
      layout: 'GuestLayout',
      requiresVisitor: true,
    },
  },
  {
    path: '/buying',
    name: 'PublicPublishedBuyingTenders',
    component: () =>
      import(
        /* webpackChunkName: "published-buying-tender-list " */ '@/views/PublishedTender/PublishedBuyingTendersList.vue'
      ),
    meta: {
      layout: 'GuestLayout',
      requiresVisitor: true,
    },
  },
  {
    path: '/buying-tenders/:id/make-offer',
    name: 'MakeBuyingOffer',
    component: () => import(/* webpackChunkName: "make-buying-offer" */ '@/views/PublishedTender/MakeBuyingOffer.vue'),
    meta: { allowedRoles: [UserRoles.client], requiresAuth: true, layout: 'LoggedInLayout' },
  },
  {
    path: '/selling-tenders/:id/make-offer',
    name: 'MakeSellingOffer',
    component: () =>
      import(/* webpackChunkName: "make-selling-offer" */ '@/views/PublishedTender/MakeSellingOffer.vue'),
    meta: { allowedRoles: [UserRoles.client], requiresAuth: true, layout: 'LoggedInLayout' },
  },
  {
    path: '/activate',
    name: 'ActivateUser',
    component: () => import(/* webpackChunkName: "activate" */ '@/views/Auth/ActivateUser.vue'),
    meta: { requiresVisitor: true, layout: 'AuthLayout' },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Auth/Login.vue'),
    meta: {
      requiresVisitor: true,
      layout: 'AuthLayout',
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgot-password" */ '@/views/Auth/ForgotPassword.vue'),
    meta: { requiresVisitor: true, layout: 'AuthLayout' },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "reset-password" */ '@/views/Auth/ResetPassword.vue'),
    meta: { requiresAuth: true, layout: 'AuthLayout' },
  },
  {
    path: '/create-password',
    name: 'CreatePassword',
    component: () => import(/* webpackChunkName: "create-password" */ '@/views/Auth/CreatePassword.vue'),
    meta: { requiresVisitor: true, layout: 'AuthLayout' },
  },
  {
    path: '/two-factor-auth',
    name: 'TwoFactorAuth',
    component: () => import(/* webpackChunkName: "two-factor-auth" */ '@/views/Auth/TwoFactorAuth.vue'),
    meta: { requiresVisitor: true, layout: 'AuthLayout' },
  },
  {
    path: '/enable-2fa',
    name: 'Enable2FA',
    component: () => import(/* webpackChunkName: "enable2-fa" */ '@/views/Auth/Enable2FA.vue'),
    meta: { requiresVisitor: true, layout: 'AuthLayout' },
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Dashboard/Home.vue'),
    meta: { requiresAuth: true, layout: 'LoggedInLayout', drawer: { label: 'home', icon: 'mdi-home-outline' } },
  },
  {
    path: '/client',
    name: 'Client',
    component: () => import(/* webpackChunkName: "clients" */ '@/views/Clients/ClientList.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector, UserRoles.finance],
      requiresAuth: true,
      layout: 'LoggedInLayout',
      drawer: { label: 'clients', icon: 'mdi-briefcase-outline' },
    },
  },
  {
    path: '/client/create',
    name: 'CreateClient',
    component: () => import(/* webpackChunkName: "create-client" */ '@/views/Clients/CreateClient.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },
  {
    path: '/client/edit/:id',
    name: 'EditClient',
    component: () => import(/* webpackChunkName: "edit-client" */ '@/views/Clients/EditClient.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },
  {
    path: '/client/show/:id',
    name: 'ShowClient',
    component: () => import(/* webpackChunkName: "show-client" */ '@/views/Clients/ShowClient.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector, UserRoles.finance],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },
  {
    path: '/client/:id/invoices',
    name: 'ClientInvoices',
    component: () => import(/* webpackChunkName: "clients" */ '@/views/Invoice/ClientInvoices.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector, UserRoles.finance],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },
  //  NOTE: This module is commented due to business logic
  // {
  //   path: '/cross-border-capacity',
  //   name: 'CrossBorderCapacity',
  //   component: () =>
  //     import(/* webpackChunkName: "cross-border-capacity" */ '@/views/CrossBorderCapacity/CrossBorderCapacityList.vue'),
  //   meta: {
  //     allowedRoles: [UserRoles.admin, UserRoles.operationOperator],
  //     requiresAuth: true,
  //     layout: 'LoggedInLayout',
  //     drawer: {
  //       label: 'crossBorderCapacity.crossBorderCapacity',
  //       icon: 'mdi-lightning-bolt-outline',
  //     },
  //   },
  // },
  {
    path: '/tenders/create/:type',
    name: 'CreateTender',
    component: () => import(/* webpackChunkName: "create-tender" */ '@/views/Tender/CreateTender.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.finance, UserRoles.operationDirector, UserRoles.tradingDirector],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },
  {
    path: '/tenders/edit/:id',
    name: 'EditTender',
    component: () => import(/* webpackChunkName: "edit-tender" */ '@/views/Tender/EditTender.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.finance, UserRoles.operationDirector, UserRoles.tradingDirector],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },
  {
    path: '/tenders',
    name: 'Tenders',
    component: () => import(/* webpackChunkName: "tenders" */ '@/views/Tender/Tenders.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector, UserRoles.tradingDirector],
      requiresAuth: true,
      layout: 'LoggedInLayout',
      drawer: {
        label: 'tenders',
        icon: 'mdi-text-box-multiple-outline',
      },
    },
    children: [
      {
        path: '/buying-tenders',
        name: 'BuyingTenders',
        component: () => import(/* webpackChunkName: "buying-tenders" */ '@/views/Tender/BuyingTenders.vue'),
        meta: {
          requiresAuth: true,
          layout: 'LoggedInLayout',
          label: 'buyingTenders',
        },
      },
      {
        path: '/selling-tenders',
        name: 'SellingTenders',
        component: () => import(/* webpackChunkName: "selling-tenders" */ '@/views/Tender/SellingTenders.vue'),
        meta: {
          requiresAuth: true,
          layout: 'LoggedInLayout',
          label: 'sellingTenders',
        },
      },
    ],
  },
  {
    path: '/published-tenders',
    name: 'PublishedTenders',
    component: () => import(/* webpackChunkName: "published-tenders" */ '@/views/PublishedTender/PublishedTenders.vue'),
    meta: {
      allowedRoles: [UserRoles.client, UserRoles.commissioner, UserRoles.operationOperator, UserRoles.tradingDirector],
      requiresAuth: true,
      layout: 'LoggedInLayout',
      drawer: {
        label: 'publishedTenders.label',
        icon: 'mdi-text-box-multiple-outline',
      },
    },
    children: [
      {
        path: '/published-tenders/buying',
        name: 'PublishedBuyingTenders',
        component: () =>
          import(
            /* webpackChunkName: "published-buying-tenders" */ '@/views/PublishedTender/PublishedBuyingTendersList.vue'
          ),
        meta: {
          requiresAuth: true,
          layout: 'LoggedInLayout',
          label: 'buyingTenders',
        },
      },
      {
        path: '/published-tenders/selling',
        name: 'PublishedSellingTenders',
        component: () =>
          import(
            /* webpackChunkName: "published-selling-tenders" */ '@/views/PublishedTender/PublishedSellingTendersList.vue'
          ),
        meta: {
          requiresAuth: true,
          layout: 'LoggedInLayout',
          label: 'sellingTenders',
        },
      },
    ],
  },
  {
    path: '/closed-tender-details/:id',
    name: 'ClosedTenderDetails',
    component: () => import(/* webpackChunkName: "closed-tender-details" */ '@/views/Tender/views/TenderDetails.vue'),
    meta: {
      allowedRoles: [
        UserRoles.admin,
        UserRoles.operationOperator,
        UserRoles.operationDirector,
        UserRoles.tradingDirector,
      ],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },
  {
    path: '/my-tenders',
    name: 'MyTenders',
    component: () => import(/* webpackChunkName: "myTenders" */ '@/views/Tender/MyTender/MyTendersList.vue'),
    meta: {
      allowedRoles: [UserRoles.client],
      requiresAuth: true,
      layout: 'LoggedInLayout',
      drawer: { label: 'myTenders.label', icon: 'mdi-tag-outline' },
    },
  },
  {
    path: '/my-tenders/:id/:viewType',
    name: 'MyTenderOffer',
    component: () =>
      import(/* webpackChunkName: "edit-my-tender-offer" */ '@/views/Tender/MyTender/MyTenderOfferForm.vue'),
    meta: {
      allowedRoles: [UserRoles.client],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },
  {
    path: '/evaluate-buying-tender/:id',
    name: 'EvaluateBuyingTender',
    component: () =>
      import(/* webpackChunkName: "evaluate-buying-tender" */ '@/views/Tender/Evaluation/EvaluateBuyingTender.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector, UserRoles.tradingDirector],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },
  {
    path: '/evaluate-selling-tender/:id',
    name: 'EvaluateSellingTender',
    component: () =>
      import(/* webpackChunkName: "evaluate-selling-tender" */ '@/views/Tender/Evaluation/EvaluateSellingTender.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector, UserRoles.tradingDirector],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/User/UsersList.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector, UserRoles.finance],
      requiresAuth: true,
      layout: 'LoggedInLayout',
      drawer: { label: 'users', icon: 'mdi-account-multiple-outline' },
    },
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: () => import(/* webpackChunkName: "users" */ '@/views/Invoice/InvoicesList.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector, UserRoles.finance],
      requiresAuth: true,
      layout: 'LoggedInLayout',
      drawer: { label: 'invoices', icon: 'mdi-receipt-outline' },
    },
  },
  {
    path: '/users/create',
    name: 'CreateUser',
    component: () => import(/* webpackChunkName: "create-user" */ '@/views/User/CreateUser.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector, UserRoles.finance],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },
  {
    path: '/users/edit/:id',
    name: 'EditUser',
    component: () => import(/* webpackChunkName: "edit-user" */ '@/views/User/EditUser.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector, UserRoles.finance],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },
  {
    path: '/users/show/:id',
    name: 'ShowUser',
    component: () => import(/* webpackChunkName: "show-user" */ '@/views/User/ShowUser.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector, UserRoles.finance],
      requiresAuth: true,
      layout: 'LoggedInLayout',
    },
  },
  {
    path: '/document-types',
    name: 'DocumentType',
    component: () => import(/* webpackChunkName: "document" */ '@/views/DocumentType/DocumentTypeList.vue'),
    meta: {
      allowedRoles: [UserRoles.admin, UserRoles.operationDirector, UserRoles.finance],
      requiresAuth: true,
      layout: 'LoggedInLayout',
      drawer: { label: 'documentTypes', icon: 'mdi-folder-outline' },
    },
  },
]
export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})
router.beforeEach((to, _from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth || record.meta.requiresVisitor) && isMobile()) {
    next({ name: 'LandingForMobileDevices' })
  } else if (to.matched.some((record) => record.meta.requiresVisitor) && store.getters.loggedIn) {
    next({ name: 'Home' })
  } else if (to.matched.some((record) => record.meta.requiresAuth) && !store.getters.loggedIn) {
    next({ name: 'Login' })
  } else {
    const notAllowed = to.matched.some((record) => {
      return record.meta.allowedRoles && !record.meta.allowedRoles.includes(store.getters.role)
    })
    if (notAllowed) next({ name: 'Home' })
    next()
  }
})
