import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from '@/store/auth'
import { me } from '@/store/me'
import VuexPersistence from 'vuex-persist'
import type { IRootState } from './types'

Vue.use(Vuex)

const vuexPersist = new VuexPersistence<IRootState>({
  key: 'root',
  storage: window.localStorage,
})

export default new Vuex.Store<IRootState>({
  state: {
    version: '1.0.0',
  },
  modules: { auth, me },
  mutations: {},
  actions: {},
  plugins: [vuexPersist.plugin],
})
