






import Vue from 'vue'
import DashboardService from '@/services/dashboard'
import { SplitTimeDTO } from '@/components/Timer.vue'

export default Vue.extend({
  name: 'ServerTime',
  data: function (): { serverTime: number } {
    return { serverTime: 0 }
  },
  computed: {
    splitTime(): Omit<SplitTimeDTO, 'days'> {
      const hours = Math.floor((this.serverTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((this.serverTime % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((this.serverTime % (1000 * 60)) / 1000)

      return {
        hours: this.formatNumber(hours.toString()),
        minutes: this.formatNumber(minutes.toString()),
        seconds: this.formatNumber(seconds.toString()),
      }
    },
  },
  methods: {
    formatNumber(number: string): string {
      return number.length === 1 ? `0${number}` : number
    },
    async fetchTime(): Promise<void> {
      const { data } = await DashboardService.serverTime()
      const serverTime = data.data
      const { hoursInMilliseconds, minutesInMilliseconds, secondsInMilliseconds } = {
        hoursInMilliseconds: +serverTime.split(':')[0] * 1000 * 60 * 60,
        minutesInMilliseconds: +serverTime.split(':')[1] * 1000 * 60,
        secondsInMilliseconds: +serverTime.split(':')[2] * 1000,
      }

      this.serverTime = hoursInMilliseconds + minutesInMilliseconds + secondsInMilliseconds
    },
    async incrementTime(): Promise<void> {
      this.serverTime += 1000
    },
  },
  async mounted() {
    await this.fetchTime()
    addEventListener('visibilitychange', () => {
      if (!document.hidden) this.fetchTime()
    })
    setInterval(this.incrementTime, 1000)
  },
})
