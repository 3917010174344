import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import type { Module } from 'vuex'
import type { IAuthState } from '@/store/auth/types'
import type { IRootState } from '@/store/types'

export const state: IAuthState = {
  accessToken: null,
  refreshToken: null,
}

const namespaced = false

export const auth: Module<IAuthState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
