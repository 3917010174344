






































import { mapActions, mapGetters } from 'vuex'
import { UserRoles } from '@/enums/UserRoles'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'AppBarUserMenu',
  methods: {
    ...mapActions(['logout']),
    logOut: function () {
      this.logout()
    },
    gotoUserProfile: function () {
      //todo redirect to profile view
    },
  },
  computed: {
    ...mapGetters(['fullName', 'role', 'companyName']),
    isNotClient() {
      return this.role !== UserRoles.client
    },
  },
})
