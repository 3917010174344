import api from '@/plugins/api'
import { GetActiveTendersResponse, GetStatisticsResponse, GetTimeResponse } from '@/services/dashboard/types'

const DashboardService = {
  all(query = ''): Promise<GetActiveTendersResponse> {
    return api().get(`/dashboard/active${query}`)
  },

  statistics(): Promise<GetStatisticsResponse> {
    return api().get(`/dashboard/statistics`)
  },

  serverTime(): Promise<GetTimeResponse> {
    return api().get(`/dashboard/hour`)
  },
}

export default DashboardService
