import type { GetterTree } from 'vuex'
import type { IAuthState } from '@/store/auth/types'
import type { IRootState } from '@/store/types'

export const getters: GetterTree<IAuthState, IRootState> = {
  loggedIn({ accessToken }: IAuthState): boolean {
    return !!accessToken
  },
  accessToken({ accessToken }: IAuthState): string | null {
    return accessToken
  },
}
