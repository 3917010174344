import type { MutationTree } from 'vuex'
import type { IAuthState } from '@/store/auth/types'

export const mutations: MutationTree<IAuthState> = {
  LOGIN(state: IAuthState, authData: IAuthState): void {
    state.accessToken = authData.accessToken
    state.refreshToken = authData.refreshToken
  },
  LOGOUT(state: IAuthState): void {
    state.accessToken = ''
    state.refreshToken = ''
  },
  REFRESH_TOKEN(state: IAuthState, authData: IAuthState): void {
    state.accessToken = authData.accessToken
    state.refreshToken = authData.refreshToken
  },
}
