import moment from 'moment'
import momentTz from 'moment-timezone'

import { LotBidDTO, TimeIntervalDTO } from '@/services/tender/types'

export const formatDateTimeStandard = (date: string): string => {
  return moment.utc(date).format('DD/MM/YYYY HH:mm')
}

export const formatDate = (date: string): string => {
  return moment.utc(date).format('DD/MM/YYYY')
}

export const formatTime = (time: string): string => {
  return moment(time, 'HH:mm:ss').format('HH:mm')
}

export const formatWeekday = (date: string): string => {
  return moment.utc(date).format('dddd')
}

export const errorMessages = (messages: string | string[]): string => {
  if (typeof messages === 'string') return messages
  return messages.join('<br>')
}

export const sortLots = (lots: LotBidDTO[]): LotBidDTO[] => {
  lots.sort((a, b) => {
    return a.id - b.id
  })
  return lots
}

export const renderDeliveryProfile = (deliveryProfile: TimeIntervalDTO[]): string => {
  return deliveryProfile
    .map((profile) => `${formatTimeWithTimeLabel(profile.startTime)} - ${formatTimeWithTimeLabel(profile.endTime)}`)
    .join(' & ')
}

export const formatTimeWithTimeLabel = (time: string): string => {
  const [h, m] = time.split(':')
  return `${h} (hh) : ${m} (mm)`
}

export const formatEvaluationTime = (hour: number, minutes: number): string =>
  `${hour ? hour + '(h) :' : ''} ${minutes}(m)`

export const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const parseAndJoin = (str: string): string => {
  if (isJsonString(str)) return JSON.parse(str).join()
  return str
}

export const today = (): string => moment(new Date()).format('YYYY-MM-DD')

export const formatDateTime = (str: string): string => {
  const [date, time] = moment(str).utc(true).format('YYYY-MM-DD HH:mm:ss').split(' ')
  const [hours, minutes, unFormattedSeconds] = time.split(':')
  const [seconds] = unFormattedSeconds.split('.')
  return `${formatDate(date)} - ${hours}:${minutes}:${seconds}`
}

export const formatDateTimeAL = (str: string): string => {
  return momentTz(str).tz('Europe/Rome').format('DD/MM/YYYY - HH:mm:ss')
}

export const formatMoney = (money: number): string =>
  new Intl.NumberFormat(`de-EN`, {
    currency: `EUR`,
    style: 'currency',
  }).format(money)

export type EvaluationIntervals = {
  startDate: string
  endDate: string
  startTime: string
  endTime: string
}

export const evaluationIntervals = (
  endBiddingTime: string,
  endBiddingDate: string,
  evaluationDurationHours: number,
  evaluationDurationMinutes: number,
): EvaluationIntervals => {
  const endTender = moment(`${endBiddingDate} ${endBiddingTime}`, 'YYYY-MM-DD HH:mm')
    .add(evaluationDurationHours, 'hours')
    .add(evaluationDurationMinutes, 'minutes')
  return {
    startDate: formatDate(endBiddingDate),
    endDate: endTender.format('YYYY/MM/DD'),
    startTime: endBiddingTime,
    endTime: endTender.format('HH:mm'),
  }
}

export const isMobile = (): boolean => {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) /* eslint-disable-next-line */ ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor)
  return check
}

export const downloadBuffer = (fileName: string, buffer: ArrayBuffer): void => {
  const blob = new Blob([new Uint8Array(buffer, 8)])
  downloadBlob(fileName, blob)
}

export const downloadBlob = (fileName: string, blobParts: Blob): void => {
  const url = window.URL.createObjectURL(new Blob([blobParts]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}`)
  document.body.appendChild(link)
  link.click()
}
