




























import Vue from 'vue'

export default Vue.extend({
  name: 'AppBarLanguageSwitcher',
  data: function () {
    return {
      languages: [
        {
          langCode: 'en',
          name: 'english',
          flag: require('@/assets/images/flags/en.png'),
        },
        {
          langCode: 'al',
          name: 'albanian',
          flag: require('@/assets/images/flags/al.png'),
        },
      ],
    }
  },
  methods: {
    switchLang: function (lang: string) {
      if (localStorage.getItem('language') !== lang) localStorage.setItem('language', lang)
      if (this.$i18n.locale !== lang) this.$i18n.locale = lang
    },
  },
  computed: {
    selectedLanguage: function () {
      return {
        langCode: `${this.$i18n.locale}`,
        name: this.$i18n.locale === 'al' ? 'albanian' : 'english',
        flag: require('@/assets/images/flags/' + this.$i18n.locale + '.png'),
      }
    },
  },
})
