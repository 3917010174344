import type { MutationTree } from 'vuex'
import type { IUserState } from '@/store/me/types'

export const mutations: MutationTree<IUserState> = {
  ADD_ME(state: IUserState, userData: IUserState): void {
    state.name = userData.name
    state.email = userData.email
    state.role = userData.role
    state.companyName = userData.companyName
  },
  REMOVE_ME(state: IUserState): void {
    state.name = null
    state.email = null
    state.role = null
    state.companyName = null
  },
}
