import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 'sm',
  },
  theme: {
    themes: {
      light: {
        primary: '#36a7aa',
        accent: '#f89c6c',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        lightestGrey: '#ebebeb',
        backgroundGrey: '#F4F5FA',
        lightGreen: '#e4f8ea',
        lightRed: '#fae1e3',
      },
      dark: {
        primary: '#36a7aa',
        accent: '#f89c6c',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        lightestGrey: '#ebebeb',
        backgroundGrey: '#F4F5FA',
      },
    },
  },
})
