











































import Vue from 'vue'
import AppBarLanguageSwitcher from '@/components/AppBarLanguageSwitcher.vue'
import store from '@/store'

export default Vue.extend({
  name: 'GuestLayout',
  components: { AppBarLanguageSwitcher },
  data: (): { loggedIn: boolean } => ({ loggedIn: store.getters.loggedIn }),
})
