





























































import Vue from 'vue'
import { routes } from '@/router'
import AppBarLanguageSwitcher from '@/components/AppBarLanguageSwitcher.vue'
import AppBarUserMenu from '@/components/AppBarUserMenu.vue'
import store from '@/store'
import { RouteConfig } from 'vue-router'
import ServerTime from '@/components/ServerTime.vue'

export default Vue.extend({
  name: 'LoggedInLayout',
  components: { ServerTime, AppBarUserMenu, AppBarLanguageSwitcher },
  data: (): { role: string } => ({ role: store.getters.role }),
  computed: {
    drawerRoutes() {
      return routes.filter(
        (route) =>
          route.meta &&
          route.meta.drawer &&
          route.meta.requiresAuth &&
          (route.meta.allowedRoles ? route.meta.allowedRoles.includes(this.role) : true),
      )
    },
  },
  methods: {
    isChildOf(route: RouteConfig) {
      let found = false
      if (route.children) found = route.children.some((childRoute: RouteConfig) => childRoute.name === this.$route.name)
      return found
    },
  },
})
